import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Vitorio Bimbato </span>
            from <span className="purple"> London, UK.</span>
            <br /> 
            I'm a second year Computer Science Student at the University of Greenwich.
            <br />
            On top of that, I'm a hobby Games Developer, Part-Time barman and cook, and ontop of all that,
            I'm a CFAV at my local RAFAC Squadron.
            <br />
            <br />
            Some of my areas of expertise are: 
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> LuaU Games Development
            </li>
            <li className="about-activity">
              <ImPointRight /> Web Development
            </li>
            <li className="about-activity">
              <ImPointRight /> Mixology
            </li>
            <li className="about-activity">
              <ImPointRight /> Haute cuisine
            </li>
          </ul>

          <p style={{ textAlign: "justify" }}>
            <span className="purple">Some of my significant Experience:</span>
            <li className="about-activity">
              Lead Programmer - BritSov BAF RP Group
            </li>
            <li className="about-activity">
              Junior Programmer - BritSov UK RP Group
            </li>
            <li className="about-activity">
              IT and Technical Lead - Messina Clinic
            </li>
            <li className="about-activity">
              BloxDesk - Founder and Lead Developer
            </li>
          </p>
          
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
